import React, { useEffect } from 'react';
import { gsap } from "gsap";
import "../css/talent.css";
import ninaIcon from "../images/nina/nina_icon.png";
import yuraIcon from "../images/yura/yura_icon.png";
import renIcon from "../images/ren/ren_icon.png";
import hinataIcon from "../images/hinata/Hinata_icon.png";
import rukaIcon from "../images/ruka/Ruka_icon.png";
import ninaText from "../images/nina/Talent_page_name.png";
import yuraText from "../images/yura/Talent_page_name.png";
import renText from "../images/ren/Talent_page_name.png";
import hinataText from "../images/hinata/Talent_page_name.png";
import rukaText from "../images/ruka/Talent_page_name.png";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AniLink from "gatsby-plugin-transition-link/AniLink";

// markup
const TalentPage = () => {
   const defaultRevealAnimation = (elem, direction) => {
      direction = direction || 1;
      let x = 0, y = direction * 100;
      if (elem.classList.contains("LReveal")) {
         x = -100;
         y = 0;
      } else if (elem.classList.contains("RReveal")) {
         x = 100;
         y = 0;
      }
      elem.style.transform = "translate(" + x + "px, " + y + "px)";
      elem.style.opacity = "0";
      gsap.fromTo(elem, { x: x, y: y, opacity: 0.01 }, {
         duration: 1.5,
         x: 0,
         y: 0,
         opacity: 1,
         ease: "expo",
         overwrite: "auto",
         delay:1,
      });
   }
   const hide = (elem) => {
      gsap.set(elem, { opacity: 0.01 });
   }

   



   useEffect(() => {
      // unmountAnimation();
      let tl = gsap.timeline();
      // animation chain for all the delayed animation component
      const talentAnimation = () => {
         tl.fromTo(".talent_reveal_trans", { autoAlpha: 0.01 }, { autoAlpha: 0.24, ease: "ease", overwrite: "auto", duration: 0.7 , delay:1 })
            .fromTo(".talent_bk_circle_out", { autoAlpha: 0.01 }, { autoAlpha: 1, ease: "ease", overwrite: "auto", duration: 0.7 }, "-=0.6")
            .fromTo(".talent_reveal0", { x: -100, y: 0, autoAlpha: 0.01 }, { x: 0, y: 0, autoAlpha: 1, ease: "ease", overwrite: "auto", duration: 1 }, "-=0.5")
            .fromTo(".talent_reveal1", { x: -100, y: 0, autoAlpha: 0.01 }, { x: 0, y: 0, autoAlpha: 1, ease: "ease", overwrite: "auto", duration: 1 }, "-=0.8")
            .fromTo(".talent_reveal2", { x: -100, y: 0, autoAlpha: 0.01 }, { x: 0, y: 0, autoAlpha: 1, ease: "ease", overwrite: "auto", duration: 1 }, "-=0.8")
            .fromTo(".talent_reveal3", { x: 0, y: -20, autoAlpha: 0.01 }, { x: 0, y: 0, autoAlpha: 1, ease: "ease", overwrite: "auto", duration: 1 }, "-=1.2")
            .fromTo(".talent_reveal4", { x: 0, y: -20, autoAlpha: 0.01 }, { x: 0, y: 0, autoAlpha: 1, ease: "ease", overwrite: "auto", duration: 1 }, "-=0.8")
            .fromTo(".talent_reveal5", { x: 0, y: -20, autoAlpha: 0.01 }, { x: 0, y: 0, autoAlpha: 1, ease: "ease", overwrite: "auto", duration: 1 }, "-=0.8");
      }
      const mountAnimation = () => {
         // console.log("mounted talent")
         gsap.registerPlugin(ScrollTrigger);
         gsap.utils.toArray(".reveal").forEach(function (elem) {
            hide(elem);
            ScrollTrigger.create({
               trigger: ".talent-container",
               start: "top 60%",
               // markers:true,
               once: true,
               onEnter: function () { defaultRevealAnimation(elem) },
            });
         });
         // setting all the delayed animation component to be not visible
         gsap.set(".talent_reveal_trans", { autoAlpha: 0.01 });
         gsap.set(".talent_bk_circle_out", { autoAlpha: 0.01 });
         gsap.set(".talent_reveal0", { autoAlpha: 0.01 });
         gsap.set(".talent_reveal1", { autoAlpha: 0.01 });
         gsap.set(".talent_reveal2", { autoAlpha: 0.01 });
         gsap.set(".talent_reveal3", { autoAlpha: 0.01 });
         gsap.set(".talent_reveal4", { autoAlpha: 0.01 });
         gsap.set(".talent_reveal5", { autoAlpha: 0.01 });

         ScrollTrigger.create({
            trigger: ".talent-container",
            start: "top 60%",
            once: true,
            onEnter: talentAnimation,
         });

      }
      mountAnimation();
      // return () => { unmountAnimation(); }
   }, []);
   return (
      <div>
         <div className="talent_selection">
            <AniLink fade to="/talent/momose-nina" className="talent_link">
               <div className="talent_icon_box">
                  <img className="talent_icon" src={ninaIcon}></img>
                  <img className="talent_page_name" src={ninaText}></img>
               </div>
            </AniLink>
            <AniLink fade to="/talent/usui-yura" className="talent_link">
               <div className="talent_icon_box">
                  <img className="talent_icon" src={yuraIcon}></img>
                  <img className="talent_page_name" src={yuraText}></img>
               </div>
            </AniLink>
            <AniLink fade to="/talent/suzuri-ren" className="talent_link">
               <div className="talent_icon_box">
                  <img className="talent_icon" src={renIcon}></img>
                  <img className="talent_page_name" src={renText}></img>
               </div>
            </AniLink>
            <AniLink fade to="/talent/natori-hinata" className="talent_link">
               <div className="talent_icon_box">
                  <img className="talent_icon" src={hinataIcon}></img>
                  <img className="talent_page_name_4" src={hinataText}></img>
               </div>
            </AniLink>
            <AniLink fade to="/talent/shigure-ruka" className="talent_link">
               <div className="talent_icon_box">
                  <img className="talent_icon" src={rukaIcon}></img>
                  <img className="talent_page_name_4" src={rukaText}></img>
               </div>
            </AniLink>
            {/* <AniLink fade to="/talent/momose-nina"><div className="talent_icon_box"><img className="talent_icon" src={ninaIcon}></img><img className="talent_page_name" src={ninaText}></img></div></AniLink>
            <AniLink fade to="/talent/usui-yura"><div className="talent_icon_box"><img className="talent_icon" src={yuraIcon}></img><img className="talent_page_name" src={yuraText}></img></div></AniLink> */}
         </div>
         <div className="talent_background"></div>
      </div>
   )
}

export default TalentPage
