// import * as React from "react";
import React, { useEffect } from 'react';
// import { graphql, Link } from "gatsby"
// import "../../css/talent.css";
import Body from "../../images/ruka/Ruka_model.png";
import Key from "../../images/ruka/Ruka_key.png";
import number from "../../images/ruka/05.png";
import logo from "../../images/ruka/RukaName.png";
import TalentCore from '../../component/talentCore';

const ruka_details = {
   name: `ruka`,
   description: `PROJECT NEBULAに突如やってきた新星。
   冷静な判断力と行動力を持ち合わせる戦略家。
   温かい歌声で孤独に寄り添う孤高の少女。`,
   slogan_top: `「何もかも終わりだって思うのはまだ早い」`,
   slogan_bot: `「雨音のように寄り添う歌を届けたい」`,
   twitter: "https://x.com/shigure_ruka",
   youtube: "https://www.youtube.com/@shigure_ruka",
   hashtag: "#紫雨るか",
   illustrator: "TTOSOM",
   illustrator_link: "https://x.com/ttosom58",
   modeler: "NYACHI",
   modeler_link: "https://x.com/nyachi_v",
   number: number,
   logo: logo,
   keyIllust: Key,
   baseIllust: Body
}

const Shigure_Ruka = () => {
   return (
      <TalentCore details={ruka_details}/>
   )
}

export default Shigure_Ruka